var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outside"},[_vm._m(0),_c('div',{staticClass:"efforts"},[_c('div',{staticClass:"Restoration"},[_c('div',{staticClass:"title"},[_vm._v("修复工艺")]),_c('div',{staticClass:"titleEng"},[_vm._v("Restoration process")]),_c('el-row',{staticClass:"list",attrs:{"gutter":27}},_vm._l((_vm.effortsData),function(it,index){return _c('el-col',{key:index,attrs:{"span":6}},[_c('div',{staticClass:"img",style:({
            backgroundImage: 'url(' + it.imgUrl + ')',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center',
          })},[_c('div',{staticClass:"case",on:{"click":function($event){return _vm.toRepairProcessDetails(index)}}},[_c('div',{staticClass:"case_title"},[_c('span',[_vm._v(_vm._s(it.title))])])])])])}),1),_c('el-row',{staticClass:"list2",attrs:{"gutter":27}},_vm._l((_vm.effortsData),function(it,index){return _c('el-col',{key:index,staticStyle:{"margin-top":"20px"},attrs:{"span":12}},[_c('div',{staticClass:"img",style:({
            backgroundImage: 'url(' + it.imgUrl + ')',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center',
          }),on:{"click":function($event){return _vm.toRepairProcessDetails(index)}}},[_c('div',{staticClass:"case"},[_c('div',{staticClass:"case_title"},[_c('span',[_vm._v(_vm._s(it.title))])])])])])}),1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-banner"},[_c('img',{attrs:{"src":require("../../assets/img/repairProcess/banner.jpg"),"alt":"","srcset":""}})])}]

export { render, staticRenderFns }