<template>
  <div class="outside">
    <!-- <banner /> -->
    <div class="top-banner">
      <img src="../../assets/img/repairProcess/banner.jpg" alt="" srcset="">
    </div>
    <div class="efforts">
      <div class="Restoration">
        <div class="title">修复工艺</div>
        <div class="titleEng">Restoration process</div>
        <el-row :gutter="27" class="list">
          <el-col :span="6" v-for="(it, index) in effortsData" :key="index">
            <div :style="{
              backgroundImage: 'url(' + it.imgUrl + ')',
              'background-repeat': 'no-repeat',
              'background-size': 'cover',
              'background-position': 'center',
            }" class="img">
              <div class="case" @click="toRepairProcessDetails(index)">
                <div class="case_title">
                  <span>{{ it.title }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="27" class="list2">
          <el-col :span="12" v-for="(it, index) in effortsData" :key="index" style="margin-top: 20px;">
            <div :style="{
              backgroundImage: 'url(' + it.imgUrl + ')',
              'background-repeat': 'no-repeat',
              'background-size': 'cover',
              'background-position': 'center',
            }" class="img" @click="toRepairProcessDetails(index)">
              <div class="case">
                <div class="case_title">
                  <span>{{ it.title }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import banner from '/src/components/banner.vue'
import metal from '/src/assets/img/home/metal.jpg'
import ceramics from '/src/assets/img/home/ceramics.jpg'
import paintingCalligraphy from '/src/assets/img/home/paintingCalligraphy.jpg'
import ancientBooks from '/src/assets/img/home/ancientBooks.jpg'

export default {
  name: 'Home',
  components: { banner },
  data() {
    return {
      effortsData: [{
        title: '金属文物修复',
        imgUrl: metal,
      }, {
        title: '陶瓷文物修复',
        imgUrl: ceramics,
      }, {
        title: '书画文物修复',
        imgUrl: paintingCalligraphy,
      }, {
        title: '古籍文物修复',
        imgUrl: ancientBooks,
      }],
    }
  },
  methods: {
    toRepairProcessDetails(index) {
      this.$router.push({ name: 'RepairProcessDetails', params: { index: index } })
    },
  },
}
</script>

<style lang="scss" scoped>
.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.outside {
  background-color: #F9F7F3;
}

@media screen and (min-width: 921px) {
  .outside {
    padding-top: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .outside {
    padding-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .outside {
    padding-top: 70px;
  }
}

.outside {
  @media screen and (min-width: 921px) {
    .efforts {
      padding-bottom: 30px;
    }

    .Restoration {
      width: 62.5%;
      height: 600px;
      background-size: 100% 600px;
    }

    .list2 {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 920px) {
    .Restoration {
      width: 80%;
      height: 600px;
      background-size: 100% 600px;
    }

    .list2 {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .Restoration {
      width: 90%;
      height: 1000px;
      background-size: 100% 1000px;
    }

    .list {
      display: none;
    }
  }

  .efforts {
    width: 100%;
    position: relative;

    .Restoration {
      margin: 0px auto;
      position: relative;

      .title {
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-Heavy;
        font-weight: 800;
        text-align: left;
        color: #333;
        padding-top: 60px;
      }

      .titleEng {
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 12px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(102, 102, 102, .4);
        line-height: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
      }

      .titleEng::before {
        content: "";
        display: block;
        width: 109px;
        height: 6px;
        background: #A40000;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      .list {
        margin-top: 41px;
        margin-bottom: 30px;

        .img {
          cursor: pointer;
          width: 100%;
          height: 360px;
          font-size: 20px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .case {
            display: flex;
            align-items: center;
            flex-direction: column;

            .case_title {
              width: 100%;
              background: rgba(0, 0, 0, .5);
              text-align: center;
              padding: 10px 0;
              font-size: 16px;
            }

            .case_bottom {
              font-size: 15px;
              margin-top: 15px;
            }
          }
        }

        .img:hover {
          .case {
            height: 100%;
          }

          .case_title {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, .5);
          }
        }
      }

      .list2 {
        margin-top: 41px;
        margin-bottom: 30px;

        .img {
          // width: 280px;
          width: 100%;
          height: 360px;
          font-size: 20px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .case {
            padding: 0 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 40px;

            .case_title {
              width: 100%;
              text-align: center;
              padding-bottom: 15px;
            }

            .case_bottom {
              font-size: 15px;
              margin-top: 15px;
            }
          }
        }
      }
    }

    img {
      width: 811px;
      height: 1000px;
      position: absolute;
    }
  }
}
</style>
